
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import InputItem from '@/components/InputItem.vue'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import md5 from 'js-md5'
import Authorization from '@/components/authorization.vue'
import GlobalMixin from '@/mixins/global'
import randomString from '@/utils/random'
import getCurrentQuery from '@/utils/querystrings'
import { isWechat } from '@/utils/env'
import cookies from 'js-cookie'
const CryptoJS = require('crypto-js')

@Component({
  name: 'passportLogin',
  components: {
    'input-item': InputItem,
    'authorization': Authorization
  },
  mixins: [GlobalMixin]
})
export default class PassportLoginSignPage extends Vue {
  @Provide() public account: string = ''
  @Provide() public passType: string = 'password'
  @Provide() public pass: string = ''
  @Provide() public isShowPass: boolean = false
  @Provide() public apiError: string = ''
  @Provide() public requesting: boolean = false
  @Provide() public policySelect: boolean = false
  @Provide() public maidanbaShow: boolean = false
  @Provide() public host: string = ''
  @Provide() public redirect: string = '' // 这里存贮的是没有解码的
  @Provide() public source: string = ''

  public created () {
    this.host = String(decodeURIComponent(getCurrentQuery('host') || ''))
    this.redirect = getCurrentQuery('redirect') || ''
    this.source = getCurrentQuery('source') || sessionStorage.getItem('source') || ''
    this.maidanba()
  }

  @Emit()
  public maidanba () {
    let url = decodeURIComponent(this.redirect)
    if (!/^https:/gi.test(url)) {
      url = 'https://m.benlai.com' + unescape(this.redirect || '')
    }
    if (navigator.userAgent.indexOf('com.bankcomm.maidanba') > -1) {
      this.maidanbaShow = true
      location.href = `${configs.passportRoot}authorization/maidanbalogin?url=` + encodeURI(url)
    }
  }

  @Emit()
  public setAccount (detail: any) {
    this.account = detail.value
  }
  @Emit()
  public setPass (detail: any) {
    this.pass = detail.value
  }

  @Emit()
  public setPassShow () {
    this.isShowPass = !this.isShowPass
    if (this.isShowPass) {
      this.passType = 'text'
    } else {
      this.passType = 'password'
    }
  }

  @Emit()
  public async login () {
    if (this.requesting) {
      return
    }
    this.apiError = this.mobileError()
    if (!this.policySelect) {
      this.$toasted.show('请阅读并勾选页面协议')
      return
    }
    if (this.apiError) {
      this.requesting = false
      return
    }
    const data:any = {
      grant_type: 'Password',
      userName: this.account,
      password: md5(this.pass)
    }
    let iv = randomString(16)
    // let iv = 'P67P2jdDrNCpzQXt'
    const time = Math.round(Date.now() / 1000)
    const message = `${this.account}|${time}`
    // const message = '15858585858|1637056682'
    // const key = 'k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav'
    const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
    data.salt = iv
    iv = CryptoJS.enc.Utf8.parse(iv)
    const encrypted = CryptoJS.AES.encrypt(message,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })
    data.userName = encrypted.toString()
    this.requesting = true
    try {
      const res = await httpHelper.requestToken(data)
      this.apiError = ''
      cookies.set('UserAddressInfo', '', {
        domain: '.benlai.com'
      })
      location.replace(`https://mc.benlai.com/e-sign.html?phone=${this.account}&redirect=${encodeURIComponent(`${configs.lifeRoot}m/V82HHR08`)}`)
    } catch (err) {
      // this.goTo(err, 1, 3)
      this.apiError = err.msg
    } finally {
      this.requesting = false
    }
  }

  @Emit()
  public mobileError () {
    return (mobileRegExp.test(this.account) || emailRegExp.test(this.account)) ? '' : '您输入的账户格式不正确，请重新输入'
  }

  @Emit()
  public toFind () {
    this.$router.replace('/find')
  }
  @Emit()
  public selectPolicy () {
    this.policySelect = !this.policySelect
  }
}
